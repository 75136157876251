import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: '#30C67C',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#40C9FF',
      contrastText: '#ffffff',
    },
    grey: {
      dark: '#293240',
      main: '#B1B1B1',
      border: '#E5E8ED',
      light: '#97A3B4',
      background: '#F9F9F9',
      overlay: '#293240',
      hover: '#E5E8ED',
      textPrimary: '#222721',
    },
    success: {
      // main: '#8FDDC2',
      main: '#2DB77E',
    },
    error: {
      main: '#D45163',
    },
    warning: {
      main: '#F9D952',
    },
    info: {
      main: '#40C9FF',
    },
    extra: {
      lightGrey: '#E7E7E7',
      grey: '#D8D8D8',
      pink: '#F57A9D',
      brightPink: '#F94BA6',
      yellow: '#FED924',
      gold: '#F5B529',
      orange: '#FE953D',
      limeGreen: '#2EE88B',
      forestGreen: '#36AD8B',
      darkGreen: '#3E5F61',
      purple: '#A046E4',
      royalBlue: '#425CFF',
      darkBlue: '#233294',
      system: '#C9E7E5',
      white: '#fff',
      frozen: '#3865f4',
    },
    text: {
      primary: '#222721',
      secondary: '#000000',
      disabled: '#333333',
      hint: '#222721',
    },
    background: {
      default: '#fff',
    },
    action: {
      disabledBackground: '#B1B1B1',
      disabled: '#999999',
    },
  },
  shadows: [
    'none',
    '0 1px 3px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.2)',
    '0 1px 8px rgba(0,0,0,0.12), 0 3px 4px rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.2)',
    '0 1px 14px rgba(0,0,0,0.12), 0 5px 8px rgba(0,0,0,0.14), 0 3px 5px -1px rgba(0,0,0,0.2)',
    '0 8px 10px rgba(0,0,0,0.12), 0 6px 30px 5px rgba(0,0,0,0.14), 0 16px 24px 2px rgba(0,0,0,0.2)',
    ...Array(20).fill(
      '0 1px 8px rgba(0,0,0,0.12), 0 3px 4px rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.2)',
    ),
  ],
  typography: {
    fontFamily: 'proxima-nova',
    fontSize: 16,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontSize: '16px',
      },
      h1: {
        fontSize: '20px',
        fontFamily: 'avant-garde',
      },
      h2: {
        fontSize: '18px',
        fontFamily: 'avant-garde',
      },
      h3: {
        fontSize: '16px',
        fontFamily: 'avant-garde',
      },
      h4: {
        fontFamily: 'avant-garde',
      },
      h5: {
        fontFamily: 'avant-garde',
      },
      h6: {
        fontFamily: 'avant-garde',
        fontWeight: 'normal',
      },
      body1: {
        fontSize: '16px',
      },
      body2: {
        fontSize: '18px',
      },
      // subtitle1: {
      //     fontSize: '17px',
      //     fontWeight: 600
      // },
      // subtitle2: {
      //     fontSize: '16px',
      //     fontWeight: '400'
      // },
      overline: {
        fontSize: '16px',
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: '#2D2D2D',
        color: '#EFF3F6',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiListItemText: {
      root: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 10px',
      },
      body: {
        fontSize: '.75rem',
      },
      head: {
        fontSize: '.80rem',
        fontWeight: 500,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '6px',
        marginBottom: '8px',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 6,
        paddingRight: 4,
      },
    },
    MuiButtonBase: {
      root: {
        [breakpoints.up('sm')]: {
          whiteSpace: 'nowrap',
        },
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: '100px',
        color: '#222721',
        margin: '5px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow:
            '0px 1px 8px rgba(0,0,0,0.12), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.2)',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      text: {
        color: '#222721',
        padding: '12px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'initial',
        },
        '&:focus': {
          boxShadow: 'none',
          backgroundColor: 'initial',
        },
      },
      textPrimary: {
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'initial',
        },
        '&:focus': {
          boxShadow: 'none',
          backgroundColor: 'initial',
        },
      },
      contained: {
        borderRadius: '24px',
        padding: '8px 20px',
        backgroundColor: '#30C67C',
        color: '#fff',
        boxShadow: 'none',
        [breakpoints.down('sm')]: {
          padding: '8px 16px',
        },
        '&:hover': {
          backgroundColor: '#30C67C',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#30C67C',
          '&::after': {
            border: '3px rgba(0,0,0,0.08) solid',
          },
        },
        '&.Mui-disabled': {
          color: '#fff',
        },
        position: 'relative',
        '&::after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          borderRadius: '24px',
          transition: 'all 0.25s ease',
          border: '3px transparent solid',
        },
      },
      outlined: {
        borderRadius: '24px',
        padding: '8px 20px',
        borderColor: '#30C67C',
        backgroundColor: '#fff',
        color: '#30C67C',
        boxShadow: 'none',
        [breakpoints.down('sm')]: {
          padding: '8px 16px',
        },
        '&:hover': {
          backgroundColor: '#00CE7F',
          color: '#fff',
        },
        '&:active': {
          backgroundColor: '#2DB672',
          boxShadow: 'none',
          '&::after': {
            border: '3px rgba(0,0,0,0.08) solid',
          },
        },
        position: 'relative',
        '&::after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          borderRadius: '24px',
          transition: 'all 0.25s ease',
          border: '3px transparent solid',
        },
      },
      containedSizeSmall: {
        padding: '4px 14px',
      },
      outlinedSizeSmall: {
        padding: '12px 48px',
        [breakpoints.down('sm')]: {
          padding: '12px 20px',
        },
      },
      containedSizeLarge: {
        padding: '14px 54px',
        [breakpoints.down('sm')]: {
          padding: '14px 24px',
        },
      },
      outlinedSizeLarge: {
        padding: '14px 54px',
        [breakpoints.down('sm')]: {
          padding: '14px 24px',
        },
      },
      label: {
        fontFamily: 'proxima-nova-semi',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#00CE7F',
        },
        '&:active': {
          backgroundColor: '#2DB672',
          '&::after': {
            border: '3px rgba(0,0,0,0.08) solid',
          },
        },
        position: 'relative',
        '&::after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          borderRadius: '24px',
          transition: 'all 0.25s ease',
          border: '3px transparent solid',
        },
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#00CE7F',
          color: '#fff',
        },
        '&:active': {
          backgroundColor: '#2DB672',
          color: '#fff',
          '&::after': {
            border: '3px rgba(0,0,0,0.08) solid',
          },
        },
        position: 'relative',
        '&::after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          borderRadius: '24px',
          transition: 'all 0.25s ease',
          border: '3px transparent solid',
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#27b8f1',
        },
        '&:active': {
          backgroundColor: '#15a6df',
          '&::after': {
            border: '3px rgba(0,0,0,0.08) solid',
          },
        },
        position: 'relative',
        '&::after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          borderRadius: '24px',
          transition: 'all 0.25s ease',
          border: '3px transparent solid',
        },
      },
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: '#27b8f1',
          color: '#fff',
        },
        '&:active': {
          backgroundColor: '#15a6df',
          color: '#fff',
          '&::after': {
            border: '3px rgba(0,0,0,0.08) solid',
          },
        },
        position: 'relative',
        '&::after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          borderRadius: '24px',
          transition: 'all 0.25s ease',
          border: '3px transparent solid',
        },
      },
    },

    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '32px 24px 24px',
        textAlign: 'center',
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: '#fff',
        [breakpoints.down('sm')]: {
          overflowY: 'initial',
          minHeight: 'auto !important',
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'proxima-nova-semi',
        fontSize: '12px',
        textTransform: 'lowercase',
        transform: 'none !important',
        position: 'relative !important',
        top: 'auto !important',
        left: 'auto !important',
        borderColor: '#E5E8ED',
        margin: '0',
        padding: '0 8px',
        lineHeight: '16px',
        '&$focused': {
          color: '#222721',
        },
      },
      asterisk: {
        color: 'red',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        fontSize: '12px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,.75)',
      },
    },
    MuiDrawer: {
      paper: {},
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    PrivateTabIndicator: {
      root: {
        height: '4px',
        borderRadius: '2px',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        overflow: 'hidden',
        color: '#222721',
        '&$input': {
          borderRadius: '12px',
          transition: 'all 0.25s ease',
          border: '1px #E5E8ED solid',
          padding: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '12px',
        border: '1px #E5E8ED solid',
      },
      input: {
        padding: '14px',
      },
    },
    MuiTabs: {
      root: {},
      indicator: {},
    },
    MuiTab: {
      root: {
        // minWidth: 'initial !important',

        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#F9F9F9',
      },
    },
    MuiTablePagination: {
      root: {
        color: '#222721',
      },
    },
  },
});

export default theme;
