import React, { useEffect } from 'react';
import Head from 'next/head';

import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeProvider } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import theme from '../src/theme';

import '../styles/styles.scss';
import config from '../config';

export default function MyApp(props) {
  const router = useRouter();
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    if (router.pathname !== '/maintenance' && config.maintenanceMode) {
      router.replace('/maintenance');
    }
  });

  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0"
        />
        <meta key="ogType" property="og:type" content="website" />
        <meta key="ogLocale" property="og:locale" content="en_US" />
        <meta key="cacheControl" httpEquiv="Cache-Control" content="no-cache" />
        <meta key="pragma" httpEquiv="Pragma" content="no-cache" />
        <meta key="expires" httpEquiv="Expires" content="0" />`
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}
